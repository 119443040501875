






























































import { Component, Vue } from "vue-property-decorator";
import { GameGroupListResponse, GameGroupResponse } from "@/generated/adminapi";
import CreateGameGroup from "@/components/defaultLayout/gameGroup/CreateGameGroup.vue";
@Component({
  components: { CreateGameGroup },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class GameGroupList extends Vue {
  title = this.$_haiiName();
  GameGroupListResponse = {} as GameGroupListResponse;
  gameGroupList = [];
  demoGameGroupList = [];

  async created(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminListGameGroup();
      this.handleFetchData(data);
    } catch (error) {
      this.$_errorMessage(error);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: GameGroupListResponse | string): void {
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      return this.$_errorMessage(data);
    }

    this.GameGroupListResponse = data;

    this.GameGroupListResponse.gameGroups?.forEach((gameGroupResponse) => {
      if (gameGroupResponse.demoFlag) {
        this.demoGameGroupList.push(gameGroupResponse);
      } else {
        this.gameGroupList.push(gameGroupResponse);
      }
    });
  }

  async moveGames(gameGroup: GameGroupResponse): Promise<void> {
    try {
      await this.$router.push({
        name: "Games",
        params: {
          UUID: gameGroup.UUID,
          DEMO: gameGroup.demoFlag + "",
          gameGroupName: gameGroup.displayName,
        },
      });
    } catch (e) {
      this.$_errorMessage(e);
    }
  }
}
