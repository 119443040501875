

















































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import {
  GameList,
  GameListRequest,
  GameListResponse,
  GameResponse,
} from "@/generated/adminapi";

@Component({
  components: {
    draggable,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class Games extends Vue {
  @Prop() readonly UUID!: string;
  @Prop() readonly DEMO!: string;
  @Prop() readonly gameGroupName!: string;
  title = this.$_haiiName();
  gameListResponse = {} as GameList[];
  deactivatedGames = [];
  activatedGames = [];
  editState = false;

  get getComponentData(): Record<"props", Record<"dense", boolean>> {
    return {
      props: {
        dense: true,
      },
    };
  }

  get onCheckDemoAndALLGroup(): boolean {
    // all 그룹이면 수정 못하게 함
    const allGroupUUID = "0eda40e7-b52f-40c7-b5ec-bcd648e0b332";
    if (this.UUID == allGroupUUID) return false;

    if (!this.DEMO) return false;

    return JSON.parse(this.DEMO);
  }

  async mounted(): Promise<void> {
    try {
      await this.fetchData(this.UUID);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  async fetchData(UUID: string): Promise<void> {
    try {
      this.$_changeLoadingState(true);
      // 서버 데이터 호출
      const data = await this.$_apiHelper().v1AdminListGame(UUID);
      this.handleFetchData(data);
    } catch (error) {
      this.$_errorMessage(error);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: GameListResponse | string): void {
    this.deactivatedGames = [];
    this.activatedGames = [];
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      return this.$_errorMessage(data);
    }

    this.gameListResponse = data.games?.map((game: GameResponse) => {
      return {
        gameItype: game.itype,
        nth: game.nth,
        enabledFlag: game.enabledFlag,
      };
    });
    if (data.total == 0) {
      this.$_haii.analytics.errorEvent(this.$_haiiText("noData"));
      return this.$_errorMessage(this.$_haiiText("noData"));
    }
    data.games.forEach((game) => {
      if (game.enabledFlag) {
        this.activatedGames.push(game);
      } else {
        this.deactivatedGames.push(game);
      }
    });
  }

  preprocessingData(): GameListRequest {
    this.deactivatedGames.forEach((game: GameResponse, index) => {
      game.enabledFlag = false;
      game.nth = index;
    });
    this.activatedGames.forEach((game: GameResponse, index) => {
      game.enabledFlag = true;
      game.nth = index;
    });

    return {
      games: this.activatedGames
        .concat(this.deactivatedGames)
        .map((game: GameResponse) => {
          return {
            gameItype: game.itype,
            nth: game.nth,
            enabledFlag: game.enabledFlag,
          };
        }),
    };
  }

  async onSave(): Promise<void> {
    try {
      const gameListRe = this.preprocessingData();
      const data = await this.$_apiHelper().v1AdminModifyGame(
        this.UUID,
        gameListRe
      );

      this.$_haii.analytics.updateEvent(
        this.$_haiiName() + "_handleFetchData",
        {
          histories: this.gameListResponse,
        },
        gameListRe
      );
      this.handleFetchData(data);
      this.editState = false;
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  onDeactivatedGameAll(): void {
    this.deactivatedGames = this.deactivatedGames.concat(this.activatedGames);
    this.activatedGames = [];
  }

  onActivatedGameAll(): void {
    this.activatedGames = this.activatedGames.concat(this.deactivatedGames);
    this.deactivatedGames = [];
  }

  banDuplicate(): void {
    const set1 = new Set(this.deactivatedGames);
    this.deactivatedGames = [...set1];

    const set2 = new Set(this.activatedGames);
    this.activatedGames = [...set2];
  }
}
