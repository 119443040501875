






































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import VForm from "vuetify/lib/components/VForm";
import { GameGroupResponse } from "@/generated/adminapi";

@Component({
  components: { TextField },
})
export default class CreateGameGroup extends Vue {
  dialog = false;
  valid = false;
  nameRules = [(v: string): boolean | string => !!v || "is required"];
  gameGroupName = "";
  demoOrNot = true;

  validate(): boolean {
    const form = this.$refs.form as typeof VForm;
    return form.validate();
  }

  async moveGames(gameGroup: GameGroupResponse): Promise<void> {
    try {
      await this.$router.push({
        name: "Games",
        params: { UUID: gameGroup.UUID },
      });
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  async onCreateGameGroup(): Promise<void> {
    try {
      if (!this.validate()) return;

      if (this.demoOrNot) {
        this.gameGroupName = "DEMO_" + this.gameGroupName;
      }

      const data = await this.$_apiHelper().v1AdminCreateGameGroup({
        displayName: this.gameGroupName,
        enabledFlag: true,
        demoFlag: this.demoOrNot,
      });

      this.handleFetchData(data);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  handleFetchData(data: GameGroupResponse | string): void {
    if (typeof data === "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(data);
      return;
    }

    this.$_haii.analytics.createEvent(this.$_haiiName() + "_handleFetchData", {
      displayName: this.gameGroupName,
      enabledFlag: true,
    });
    this.$_toastMessage("success", this.$_haiiText("registerSuccess"));

    setTimeout(() => {
      this.moveGames(data);
    }, 500);
  }
}
