










































































































import { HistoryResponse } from "@/generated/adminapi";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CistResult extends Vue {
	@Prop() testerInfo!: HistoryResponse;
}
