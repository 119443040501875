




























import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class TextField extends Vue {
  @Prop({ type: Boolean, default: false }) readonly clearable?: boolean;
  @Prop({ type: Boolean, default: false }) readonly counter?: boolean;
  @Prop({ type: Boolean, default: false }) readonly disabled?: boolean;
  @Prop({ type: String, default: "" }) readonly hint?: string;
  @Prop({ type: String, default: "" }) readonly label?: string;
  @Prop({ type: Number, default: 100 }) readonly maxlength?: number;
  @Prop({ type: String, default: "" }) readonly placeholder?: string;
  @Prop({ type: Boolean, default: false }) readonly readonly?: boolean;
  @Prop({ default: "auto" }) readonly hideDetails?: string | boolean;
  @Prop({ type: Array, default: undefined }) readonly rules?: Array<
    () => boolean | string
  >;
  @Prop() readonly type?: string;
  @Prop() readonly persistent_hint?: boolean;
  @Prop({ default: "" }) readonly appendIcon?: string;
  @Prop() readonly autocomplete?: string;

  @VModel({ type: String }) keyword!: string;

  onClear(ev: MouseEvent): void {
    this.$emit("clear", ev);
  }

  onKeyPressEnter(ev: KeyboardEvent): void {
    this.$emit("enter", ev);
  }

  onUpdateError(value: boolean): void {
    this.$emit("error", value);
  }
  onClickAppendIcon() {
    this.$emit("click:append");
  }
}
