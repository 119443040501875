









































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Pagination extends Vue {
  @Prop({ type: Number, default: 10, required: true })
  readonly itemsPerPage!: number;
  @Prop({ type: Array, default: [], required: true })
  readonly itemsPerPageOptions!: Array<number>;
  @Prop({ type: Number, default: 1, required: true }) readonly page!: number;
  @Prop({ type: Number, default: 0, required: true }) readonly total!: number;

  elWidth = 0;

  mounted(): void {
    this.onWindowResize();
  }

  get options(): Array<{ text: string | number; value: number }> {
    return this.itemsPerPageOptions.map((option) => ({
      text: option === -1 ? this.$_haiiText("all") : option,
      value: option,
    }));
  }

  get pages(): number {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get totalVisible(): number {
    return this.$vuetify.breakpoint.xs ? 3 : Math.ceil(this.elWidth / 70);
  }

  onInputItemsPerPage(value: number): void {
    this.$emit("update", { itemsPerPage: value, page: 1 });
  }

  onInputPage(value: number): void {
    this.$emit("update", { page: value });
  }

  onWindowResize(): void {
    this.elWidth = this.$el.clientWidth;
  }
}
