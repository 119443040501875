






import { Component, Vue } from "vue-property-decorator";

@Component
export default class Refresh extends Vue {
  onClick(ev: Event): void {
    this.$emit("click", ev);
  }
}
